import {
  AdditiveBlending,
  BackSide,
  CircleGeometry,
  Color,
  DirectionalLight,
  EdgesGeometry,
  Euler,
  Group,
  InstancedMesh,
  LineDashedMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  PlaneGeometry,
  RingBufferGeometry,
  Scene,
  ShaderMaterial,
  SphereGeometry,
  SpotLight,
  Vector3,
  WebGLRenderer,
} from 'three'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

const sphereDetail = 60
const sphereSize = 2
const pixelDensity = window.devicePixelRatio || 1
const orbitWidth = 0.012
const orbitRadiusInner = 3
const orbitRadiusMiddle = orbitRadiusInner * 1.45
const orbitRadiusOuter = orbitRadiusMiddle * 1.45
const orbitDetail = 90
const orbitPlanetDetail = 12
const orbitPlanetRadius = 0.1

const scene = new Scene()
const camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000)
const cameraPositionStart = {x: 0, y: 2.8, z: 8}
camera.position.z = cameraPositionStart.z
camera.position.y = cameraPositionStart.y
const cameraPositionEnd = {x: 3.75, y: 0, z: 19}
const cameraPositionAim = {x: cameraPositionStart.x, y: cameraPositionStart.y, z: cameraPositionStart.z}
const haloPositionStart = {x: -0.02, y: 0, z: 0.3}
const haloPositionAim = {x: haloPositionStart.x, y: haloPositionStart.y, z: haloPositionStart.z}
const haloPositionEnd = {x: -0.15, y: 0.075, z: 0.3}
const renderer = new WebGLRenderer({alpha: true})

// Set up planet and orbits
const planetGeo = new SphereGeometry(sphereSize, sphereDetail, sphereDetail)
const material = new MeshStandardMaterial({
  color: 0x24292e,
  metalness: 0,
  roughness: 0.9,
})
const planet = new Mesh(planetGeo, material)
scene.add(planet)
const planetWireframeMaterial = new MeshStandardMaterial({
  color: 0xffffff,
  wireframe: true,
  wireframeLinewidth: 2,
  opacity: 0.1,
  blending: AdditiveBlending,
  metalness: 0.8,
  roughness: 0.8,
})
const planetWireframeGeo = new SphereGeometry(sphereSize * 1.007, sphereDetail / 2, sphereDetail / 2)
const planetWireframe = new Mesh(planetWireframeGeo, planetWireframeMaterial)
scene.add(planetWireframe)

// Halo
const haloVert = `
uniform vec3 viewVector;
uniform float c;
uniform float p;
varying float intensity;
varying float intensityA;
void main()
{
  vec3 vNormal = normalize( normalMatrix * normal );
  vec3 vNormel = normalize( normalMatrix * viewVector );
  intensity = pow( c - dot(vNormal, vNormel), p );
  intensityA = pow( 0.63 - dot(vNormal, vNormel), p );

  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}`

const haloFrag = `
uniform vec3 glowColor;
varying float intensity;
varying float intensityA;
void main()
{
  gl_FragColor = vec4( glowColor * intensity, 1.0 * intensityA );
}`

const haloGeometry = new SphereGeometry(sphereSize, 45, 45)
const haloMaterialBlue = new ShaderMaterial({
  uniforms: {
    c: {value: 0.65},
    p: {value: 15.0},
    glowColor: {value: new Color(0xf46bbe)},
    viewVector: {value: new Vector3(0, 0, cameraPositionStart.z)},
  },
  vertexShader: haloVert,
  fragmentShader: haloFrag,
  side: BackSide,
  blending: AdditiveBlending,
  transparent: true,
  dithering: true,
})

const haloMesh = new Mesh(haloGeometry, haloMaterialBlue)
haloMesh.scale.multiplyScalar(1.06)
haloMesh.rotateX(Math.PI * 0.01)
haloMesh.rotateY(Math.PI * 0.01)
haloMesh.position.set(haloPositionStart.x, haloPositionStart.y, haloPositionStart.z)
haloMesh.renderOrder = 3
scene.add(haloMesh)

// Orbits and their planets
const orbits = new Group()
const orbitsStart = {x: -1.6, y: 0.3, z: 0}
const orbitsEnd = {x: 0, y: 0, z: 0}
const orbitsAim = {x: orbitsStart.x, y: orbitsStart.y, z: orbitsStart.z}
const dashedMaterial = new LineDashedMaterial({
  color: 0xffffff,
  linewidth: 1,
  scale: 1,
  dashSize: 0.05,
  gapSize: 0.1,
  opacity: 0.3,
  transparent: true,
})

const orbitsInnerGroup = new Group()
const orbitGeoInner = new CircleGeometry(orbitRadiusInner, orbitDetail)
const orbitWireframe = new EdgesGeometry(orbitGeoInner)
const orbitInner = new LineSegments(orbitWireframe, dashedMaterial)
orbitInner.computeLineDistances()

const orbitInnerPlanetGeo = new CircleGeometry(orbitPlanetRadius, orbitPlanetDetail)
const orbitInnerPlanetMesh = new Mesh(orbitInnerPlanetGeo, material)
orbitsInnerGroup.add(orbitInner)
orbitsInnerGroup.add(orbitInnerPlanetMesh)
orbitInnerPlanetMesh.position.set(orbitRadiusInner, 0, 0)
orbits.add(orbitsInnerGroup)

const orbitsMiddleGroup = new Group()
const orbitGeoMiddle = new CircleGeometry(orbitRadiusMiddle, orbitDetail)
const orbitWireframeMiddle = new EdgesGeometry(orbitGeoMiddle)
const orbitMiddle = new LineSegments(orbitWireframeMiddle, dashedMaterial)
orbitMiddle.computeLineDistances()
const orbitMiddlePlanetGeo = new CircleGeometry(orbitPlanetRadius, orbitPlanetDetail)
const orbitMiddlePlanetMesh1 = new Mesh(orbitMiddlePlanetGeo, material)
const orbitMiddlePlanetMesh2 = new Mesh(orbitMiddlePlanetGeo, material)
orbitsMiddleGroup.add(orbitMiddle)
orbitsMiddleGroup.add(orbitMiddlePlanetMesh1)
orbitsMiddleGroup.add(orbitMiddlePlanetMesh2)
orbitMiddlePlanetMesh1.position.set(orbitRadiusMiddle, 0, 0)
orbitMiddlePlanetMesh2.position.set(-orbitRadiusMiddle, 0, 0)
orbits.add(orbitsMiddleGroup)

let orbitHighlightedThetaCurrent = 0
let orbitHighlightedThetaAim = 0
const orbitGeoHighlighted = new RingBufferGeometry(
  orbitRadiusMiddle - orbitWidth,
  orbitRadiusMiddle,
  orbitDetail,
  1,
  0,
  orbitHighlightedThetaCurrent,
)
const orbitHighlightedThetaMax = Math.PI * 2
const highlightedMaterial = new MeshBasicMaterial({color: 0xffffff, side: BackSide})
const orbitHighlighted = new Mesh(orbitGeoHighlighted, highlightedMaterial)
orbitHighlighted.position.set(0, 0, 0.01)
orbitHighlighted.setRotationFromEuler(new Euler(Math.PI, 0, -Math.PI / 2))
orbits.add(orbitHighlighted)

const orbitGeoOuter = new CircleGeometry(orbitRadiusOuter, orbitDetail)
const orbitWireframeOuter = new EdgesGeometry(orbitGeoOuter)
const orbitOuter = new LineSegments(orbitWireframeOuter, dashedMaterial)
orbitOuter.computeLineDistances()
orbits.add(orbitOuter)

orbits.setRotationFromEuler(new Euler(orbitsStart.x, orbitsStart.y, orbitsStart.z))
scene.add(orbits)

// Set up particles

const particleCount = 300
const particleMaxX = 65
const particleMaxY = 55
const particleGeo = new PlaneGeometry(0.1, 0.1)
const particleMesh = new InstancedMesh(particleGeo, new MeshBasicMaterial(), particleCount)

// Assign random colors to the particles
const color = new Color()
const particlePalette = [0x274445, 0x3c2645, 0x44220b, 0x292b46, 0x776775]
const dummy = new Object3D()

for (let i = 0; i < particleCount; i++) {
  color.setHex(particlePalette[Math.floor(Math.random() * particlePalette.length)]!)
  particleMesh.setColorAt(i, color)

  // Assign random positions
  const randomPosition = {
    x: particleMaxX * Math.random() - particleMaxX / 2 + (Math.random() * (Math.random() * 2 - 1) * particleMaxX) / 2,
    y: particleMaxY * Math.random() - particleMaxY / 2 + (Math.random() * (Math.random() * 2 - 1) * particleMaxY) / 2,
    z: -50 - Math.random() * 10,
  }
  const randomScale = Math.random() * 2

  dummy.position.set(randomPosition.x, randomPosition.y, randomPosition.z)
  dummy.scale.set(randomScale, randomScale, 1)
  dummy.updateMatrix()
  particleMesh.setMatrixAt(i, dummy.matrix)
}
scene.add(particleMesh)

// Instance matrices will be updated every frame.
//particleMesh.instanceMatrix.setUsage( DynamicDrawUsage ) // todo: verify if necessary

observe('.js-enterprise-planet', container => {
  renderer.setSize(window.innerWidth, window.innerHeight)
  renderer.setPixelRatio(pixelDensity)

  renderer.domElement.classList.add('width-window', 'height-window')
  container.appendChild(renderer.domElement)

  /* eslint-disable-next-line github/prefer-observers */
  window.addEventListener('resize', () => {
    // TODO perf: Only run this code if the canvas element is currently visible in the viewport
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
  })

  // Setup lights
  const spotLight1 = new SpotLight(0xf46bbe, 25, 120, 0.3, 0, 1.1)
  spotLight1.position.set(-30 - sphereSize * 2.5, 90, -40)
  const directionalLight = new DirectionalLight(0x2188ff, 0.5)
  directionalLight.position.set(-40 - 50, 30, 10)
  scene.add(spotLight1, directionalLight)

  animate()
})

function animate() {
  requestAnimationFrame(animate)
  // Spin elements
  planet.rotation.x += 0.01
  planet.rotation.y += 0.01
  orbitsInnerGroup.rotation.z -= 0.005
  orbitsMiddleGroup.rotation.z -= 0.003
  orbitOuter.rotation.z -= 0.001

  // Animate camera
  camera.position.x = camera.position.x + (cameraPositionAim.x - camera.position.x) * 0.1
  camera.position.y = camera.position.y + (cameraPositionAim.y - camera.position.y) * 0.1
  camera.position.z = camera.position.z + (cameraPositionAim.z - camera.position.z) * 0.1
  // Halo
  haloMesh.position.x = haloMesh.position.x + (haloPositionAim.x - haloMesh.position.x) * 0.1
  haloMesh.position.y = haloMesh.position.y + (haloPositionAim.y - haloMesh.position.y) * 0.1

  const orbitsRotation = {
    x: orbits.rotation.x + (orbitsAim.x - orbits.rotation.x) * 0.1,
    y: orbits.rotation.y + (orbitsAim.y - orbits.rotation.y) * 0.1,
    z: orbits.rotation.z + (orbitsAim.z - orbits.rotation.z) * 0.1,
  }

  orbits.setRotationFromEuler(new Euler(orbitsRotation.x, orbitsRotation.y, orbitsRotation.z))

  // Reconstruct ring geo if it's not where it should be
  if (orbitHighlightedThetaCurrent !== orbitHighlightedThetaAim) {
    orbitHighlighted.geometry.dispose()
    orbitHighlightedThetaCurrent =
      orbitHighlightedThetaCurrent + (orbitHighlightedThetaAim - orbitHighlightedThetaCurrent) * 0.14
    const newHighlightedGeo = new RingBufferGeometry(
      orbitRadiusMiddle - orbitWidth,
      orbitRadiusMiddle,
      orbitDetail,
      1,
      0,
      orbitHighlightedThetaCurrent,
    )
    orbitHighlighted.geometry = newHighlightedGeo
  }

  renderer.render(scene, camera)
}

/* eslint-disable-next-line github/prefer-observers */
document.addEventListener('scroll', () => {
  // TODO: Cache this (don't fetch on every event)
  const platformSection = document.querySelector('.js-enterprise-proto-platform')
  if (platformSection === null) return
  if (!(platformSection instanceof HTMLElement)) return

  const padding = 150 // reach final goal this many pixels before the midpoint of the window
  const midPointOffset = window.innerHeight - platformSection.getBoundingClientRect().height
  const top = platformSection.getBoundingClientRect().top - midPointOffset - padding
  const goal = top + document.documentElement.scrollTop
  const progress = top > 0 ? Math.max(0, 1 - top / goal) : 1

  // Set new goal to animate camera to
  cameraPositionAim.x = cameraPositionStart.x + (cameraPositionEnd.x - cameraPositionStart.x) * progress
  cameraPositionAim.y = cameraPositionStart.y + (cameraPositionEnd.y - cameraPositionStart.y) * progress
  cameraPositionAim.z = cameraPositionStart.z + (cameraPositionEnd.z - cameraPositionStart.z) * progress

  // Compensate camera position with new halo position
  haloPositionAim.x = haloPositionStart.x + (haloPositionEnd.x - haloPositionStart.x) * progress
  haloPositionAim.y = haloPositionStart.y + (haloPositionEnd.y - haloPositionStart.y) * progress

  // Set new goal to animate orbits to
  orbitsAim.x = orbitsStart.x + (orbitsEnd.x - orbitsStart.x) * progress
  orbitsAim.y = orbitsStart.y + (orbitsEnd.y - orbitsStart.y) * progress
  orbitsAim.z = orbitsStart.z + (orbitsEnd.z - orbitsStart.z) * progress

  orbitHighlightedThetaAim = orbitHighlightedThetaMax * progress * progress
})

observe('.js-enterprise-planet-container', section => {
  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      const planetElement = document.querySelector('.js-enterprise-planet')
      if (planetElement === null) return

      for (const entry of entries) {
        planetElement.classList.toggle('enterprise-planet-stuck', entry.isIntersecting)
        const platformContent = document.querySelector('.js-enterprise-proto-platform-content')
        if (platformContent === null) return
        platformContent.classList.toggle('enterprise-platform-content-follow', entry.isIntersecting)
      }
    },
    {
      rootMargin: `0% 0% 0% 0%`,
      threshold: 0,
    },
  )

  observer.observe(section)
})
